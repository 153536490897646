.left-arrow:hover {
  cursor: pointer;
  font-size: 1.5rem;
  transition: ease-in 200ms;
  color: #0bb980;
}
.right-arrow:hover {
  cursor: pointer;
  font-size: 1.5rem;
  transition: ease-in 200ms;
  color: #0bb980;
}
.left-arrow {
  margin-right: 15px;
}
.right-arrow {
  margin-left: 15px;
}

.image42 {
  width: 450px;
}

@media (max-width: 768px) {
  .image42 {
    width: 300px;
    margin-top: 40px;
  }
}
