.landing_navbar_link_main {
  display: flex;
  justify-content: center;
  width: 60%;
  cursor: pointer;
}

.navbar_logo_main {
  width: 80px !important;
}
.landing_navbar_review {
  width: 40%;
}

.landing_navbar_navLink {
  color: white;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  padding: 0 0.8rem;
}

.landing_review_add_review_main {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.landing_navbar_add_review {
  font-size: 17px;
  color: white;
  background-color: #0bb980;
  border-radius: 8px !important;
}

/* .active_navlink {
  color: #0bb980;
  border-bottom: 4px solid #0bb980;
} */

.landing_navbar_link_btn_main {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.landing_navbar_logo {
  width: 55px;
  height: 55px;
}

.home-top-bar {
  display: flex;
  align-items: center;
  /* gap: 40rem; */
  /* position: sticky; */
  /* top: 0; */
  /* z-index: 1000; */
  background-color: #327e8c;
  padding: 0.5rem 6rem !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  height: 57px;
}

.menu-button {
  display: none !important;
  position: absolute !important;
  right: 32px !important;
}

@media (max-width: 960px) {
  .menu-button {
    display: block !important;
  }

  .navbar_logo_main {
    position: absolute !important;
    left: 32px !important;
    margin-left: 0px;
  }

  .landing_navbar_link_main,
  .landing_navbar_review {
    display: none;
  }

  .home-top-bar {
    justify-content: space-between; /* This will position the logo and the menu icon on opposite sides */
  }

  /* Other styles to make the drawer and list items look good on mobile */
  .MuiDrawer-paper {
    width: 250px;
  }

  .MuiListItem-button {
    color: #327e8c; /* Adjust the color to fit your design */
  }
}

@media (max-width: 768px) {
  .home-top-bar {
    width: auto;
  }
}