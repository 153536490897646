.about-main {
    width: 80%;

    background-color: #F5F7FE;
    margin: auto;
}
.about-sub-div img {
    width: 100%;
    /* border: 1px solid black; */

}

.about-img-text {
    /* background-color: rebeccapurple; */
    /* border: 1px solid black; */
    display: flex;
    gap: 2rem;
    align-items: center;

}

.about-img-text2 {
    /* background-color: rebeccapurple; */
    /* border: 1px solid black; */
    display: flex;
    gap: 2rem;
    align-items: center;

}

.about-img-text3 {
    /* background-color: rebeccapurple; */
    /* border: 1px solid black; */
    display: flex;
    gap: 2rem;
    align-items: center;

}

.img-box {
    /* background-color: rgb(194, 165, 221); */
    /* border: 1px solid black; */
    width: 300%;
    height: 300%;
    overflow: hidden;
    transition: all ease 1s;
    border-radius: 93px 0px 68px
}



.text-box {
    /* background-color: cadetblue; */
    /* border: 1px solid black; */
    width: 300%;
    height: 300%;
    overflow: hidden;
    transition: all ease 1s;


}

.text-box12 {
    /* background-color: cadetblue; */
    /* border: 1px solid black; */
    width: 300%;
    height: 300%;
    overflow: hidden;
    transition: all ease 1s;


}

.text-box2 {
    /* background-color: cadetblue; */
    /* border: 1px solid black; */
    display: none;


}
.about-text {
    /* background-color: chartreuse; */
    /* border: 1px solid black; */
    text-align: center;
    background: #F5F7FE;
}

.about-text p{
    font-size: 26px;
}

/*
.about-text p::after {

    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    height: 3px;
    width: 0%;
    background-color:  #0bb980;
    transition: all ease 0.5s;
}
.about-text p:hover::after {
    width: 40%;
} */

.text-box p {
    text-align: end;
    font-size: 21px;
}

.text-box12 p {
    text-align: end;
    font-size: 21px;
}

h2 {
    font-size: 50px;
    font-weight: 900;
    -webkit-text-stroke: 2px #000;
    color: transparent;
    position: relative;
    transition: all ease 1s;
    margin-top: 2px;
}

h2:hover {
    font-size: 50px;
    font-weight: 900;
    -webkit-text-stroke: 2px #000;
    color: #0bb980;
}

h2::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 0%;
    background-color:  #0bb980;
    transition: all ease 0.5s;


}

h2:hover::after {
    width: 100%;
}

.img-box img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: all ease 1s;
}

.img-box:hover img {
    scale: 1.2;
}

.contact-us-section {
    background-color: #f4f4f4;
    padding: 20px;
    margin: 32px 0px;
    text-align: center;
  }

.contact-us-section h2 {
    color: #333;
  }

.contact-us-section p {
    font-size: 18px;
    margin-top: 10px;
  }

.contact-us-section a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }

.contact-us-section a:hover {
    text-decoration: underline;
  }

@media (max-width: 768px){
    .about-sub-div img {
        width: 100%;
        /* border: 1px solid black; */

    }
    .about-text p {
        /* background-color: chartreuse; */
        /* border: 1px solid black; */
        text-align:justify;
        background: #F5F7FE;
        font-size: 19px;
        line-height: 1.6em;

    }

    .about-img-text {
        /* background-color: rebeccapurple; */
        /* border: 1px solid black; */
        display: flex;
        gap: 2rem;
        align-items: center;
        justify-content: center;
        flex-direction: column;

    }

    .about-img-text2 {
        /* background-color: rebeccapurple; */
        /* border: 1px solid black; */
        display: flex;
        gap: 2rem;
        align-items: center;
        justify-content: center;
        flex-direction: column;

    }

    .about-img-text3 {
        /* background-color: rebeccapurple; */
        /* border: 1px solid black; */
        display: flex;
        gap: 2rem;
        align-items: center;
        justify-content: center;
        flex-direction: column;



    }
    .img-box {
        /* background-color: rgb(194, 165, 221); */
        /* border: 1px solid black; */
        width: 90%;
        height: 90%;
        margin-top: 100px;
    }


    .text-box  {
        /* background-color: cadetblue; */
        /* border: 1px solid black; */
        width: 100%;
        height: 60%;
        font-size: 17px;
        text-align: left;



    }

    .text-box2{
        display: flex;
        font-size: 20px !important;

    }

    .text-box12{
        display: none;
    }

    .text-box p{
        text-align: left;
        line-height: 1.6em;
    }

    .text-box2 p{
        text-align: left;
        line-height: 1.6em;
    }
}