.privacy-main{
    width: 80%;
    margin: auto;
    padding: 40px;
}
.privacy-each {
    width: 80%;
}

@media (max-width: 768px){
    .privacy-main{
        
        margin: auto;
    }
    .privacy-each {
        width: 100%;
        font-size: 16px;
    }
   
}