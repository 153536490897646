.blog-main {
    width: 90%;
    margin: auto;
}

.blog-img {
    text-align: center;
}

.blog-img  img{
    width: 100%;
    max-width: 75rem;
    height: 20rem;
}

.blog-content {
    padding: 30px;
    text-align: start;
    width: 88%;
    margin: auto;
}
.blog-content p {
    font-size: 1.4rem;
}

@media (max-width: 768px){
    .blog-img img{
        width: 100%;
        height: 100%;
    }

    .blog-content p {
        font-size: 1.2rem;
    }
}