body {
  background-color: #f5f7fe;
}

.right_course_main {
  background-image: url("../../../images/LandingPageImage/header_background.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  border-radius: 0.4rem;
  margin: 0.2rem 0.9rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.support_clients_second_headline {
  margin-top: 3rem !important;
  height: 100% !important;
  width: 100% !important;
}

.find_course_heading {
  font-size: 2.3rem !important;
}
.right_course_heading_ {
  font-size: 3.5rem !important;
}

.trust_heading {
  font-weight: 700;
}

.supported_client_main {
  margin-bottom: 5rem;
  width: 100%;
}

.landing_supported_heading {
  font-size: 3rem;
  font-weight: 700;
}

.support_client {
  width: 15% !important;
  margin-top: 10px;
}

.dialog-box {
  width: 400px;
  height: 560px;
  padding: 0px 35px 35px 35px;
}

.dialog-sub-box textarea {
  width: 325px;
  height: 100px;
  border-radius: 5px;
}

.home-top-bar {
  display: flex;
  align-items: center;
  /* gap: 40rem; */
  /* position: sticky; */
  /* top: 0; */
  /* z-index: 1000; */
  background-color: #327e8c;
  padding: 0.5rem 6rem !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  height: 57px;
}

.logo-section {
  font-size: 2.6rem;
  font-weight: bold;
  cursor: pointer;
}

.navbar_review_btn {
  border: 3px solid white !important;
  /* color: gray !important; */
  border-radius: 10px !important;
  font-weight: bold !important;
  color: white !important;
}

/* #0BB980 */

.home-main-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  /* margin: 5rem 2rem 5rem 10rem; */
  padding: 0.5rem;
  box-sizing: border-box;
}

#navbar-search-box {
  width: 25rem;
  margin-right: 14px;
  height: 2.7em;
  border-radius: 24px;
  border: 1px solid gray;
}

#navbar-search-box:focus {
  border-color: gray;
}

.main-lgn {
  display: flex;
  flex-direction: column;
  padding: 5px;
  align-items: center;
}

.login-text {
  font-size: 2rem;
  color: black;
  font-weight: bold;
  height: 30px;
  height: 60px;
}

.input-1-box {
  display: flex;
  flex-direction: column;
  margin: 8px 0px 8px 0px;
}

.input-1-box input {
  height: 35px;
  width: 270px;
  border: 1px solid rgb(178, 176, 176);
  border-radius: 5px;
  padding-left: 10px;
  outline: none;
  transition: 0.5s;
}

.input-2-box input {
  height: 35px;
  width: 270px;
  border: 1px solid rgb(178, 176, 176);
  border-radius: 5px;
  padding-left: 10px;
  outline: none;
  transition: 0.5s;
}

input:focus {
  border: 2px solid #0bb980;
}

.input-2-box label {
  color: rgb(178, 176, 176);
}

.input-1-box label {
  color: rgb(178, 176, 176);
}

.input-2-box {
  display: flex;
  flex-direction: column;
  margin: 8px 0px 8px 0px;
}

.lgn-btn {
  width: 200px;
  background: rgb(32, 55, 226);
  background: linear-gradient(
    344deg,
    rgba(32, 55, 226, 1) 0%,
    rgba(11, 185, 128, 1) 72%
  );
  border-radius: 5px;
  height: 30px;
  margin-top: 20px;
  text-align: center;
}

.lgn-btn Button {
  color: white;
  width: 200px;
}

.top {
  width: 80%;
  min-height: 300px;
  /* background-color: #0bb980; */
  display: flex;
  justify-content: space-between;

  padding: 5rem 0;
}

.left {
  width: 45%;
  min-height: 300px;
  /* border: 2px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right {
  width: 45%;
  min-height: 300px;
  /* border: 2px solid black;  */
}

.tag {
  color: #0bb980;
  font-weight: bold;
}

.tag2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 20px 0px 20px 0px;
}

.numbers {
  color: rgb(166, 164, 164);
  font-size: 15px;
}

.bottom {
  /* width: 70vw; */
  display: flex;
  flex-direction: column;
}

.companies {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  /* gap: 3rem; */
  flex-wrap: wrap;
}

.companies img {
  height: 85px;
  width: 150px;
  /* border: 1px solid rgb(186, 183, 183); */
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

@media (max-width: 960px) {
  .support_client {
    width: 30% !important;
  }

  .top {
    flex-direction: column;
    width: 100%;
  }

  .left {
    padding-left: 38px;
    width: 85%;
  }

  .right {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .home-top-bar {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .support_client {
    width: 35% !important;
  }

  .companies {
    justify-content: space-around;
  }

  .logo-section {
    z-index: 99;
    position: absolute;
  }

  .home-top-bar-section {
    font-size: 14vw;
    width: 10%;
    margin-left: 33px;
  }

  .button-section {
    font-size: 10px;
    width: 100%;
    margin-left: 60px;
  }

  .menu-icon {
    margin-right: 0px;
  }

  .dialog-box {
    width: 97%;
    padding-right: 0px;
    padding-left: 10px;
  }

  .home-main-section {
    width: 100%;
  }

  .tag2 {
    font-size: 2.3rem;
  }

  .bottom {
    width: 100vw;
  }

  .home-top-bar-section.button-section > #navbar-rev {
    width: 90px;
    font-size: 13px;
  }
}

@media (max-width: 400px) {
  .companies {
    width: 347px;
  }

  .support_client {
    width: 80% !important;
  }

  .companies img {
    width: 190px;
    height: 90px;
  }

  .dialog-box {
    width: 90%;
    padding-right: 0px;
    padding-left: 10px;
  }
}
