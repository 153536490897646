.rev-main-cont {
  width: 100%;
  height: 100%;
}

.rev-topbar {
  background-color: #F5F7FE;
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  padding: 20px;
}

.rev-mainsection {
  background-color: rgb(251, 251, 240);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding: 15px;
  position: relative;
  justify-content: center;
  min-height: 478px;
}

.rev-content {
  margin-top: 10px;
  background-color: #ffff;
  width: 18rem;
  padding: 5px 15px 15px 15px;
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  max-height: fit-content;
  cursor: pointer;
}

.rev-content1 {
  font-size: 14px;
  color: #767474;
}

.rev-cust-cont1 span:first-child {
  font-weight: bold;
  margin-right: 5px;
  font-size: 13px;
}

.rev-cust-cont1 span:nth-child(2) {
  font-size: 10px;
  color: gray;
  margin-right: 5px;
}

.rev-cust-cont1 span:nth-child(3) {
  font-size: 12px;
  font-weight: bold;
}

/* Add this to your Reviews.css file or equivalent */
.rev-content {
  border: 1px solid #ccc;
  /* Add a border for the content box */
  /* padding: 15px; */
  position: relative;
  max-width: 400px;
  /* Adjust the maximum width as needed */
  /* Add some margin between content boxes */
}

.rev-time {
  /* Styles for the date element */
  position: absolute;
  bottom: 10px;
  /* Adjust the distance from the bottom */
  right: 10px;
  /* Adjust the distance from the right */
  font-size: 12px;
  color: #888;
}

.navbar-stack {
  flex-direction: row !important;
  gap: 40px;
  justify-content: center;
}

.navbar-field {
  width: 20%;
}

.navbar-field .MuiOutlinedInput-root input {
  border: 0px;
}

@media (max-width: 768px){
  .rev-topbar{
    font-size: 1.6rem;
  }

  .navbar-field {
    width: 45%;
  }
}